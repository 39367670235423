import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class HeroFull extends React.Component {
  constructor() {
    super();
    this.audioPlayer = React.createRef();
  }

  playAudio = () => {
    this.audioPlayer.current.play();
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content">
              <h1 className="mt-0 mb-12 reveal-scale-down" data-reveal-delay="300">
                Engage your subscribers with audio
              </h1>
              <div className="container-xs">
                <p className="m-0 mb-32 reveal-scale-down" data-reveal-delay="400">
                  Use AI to turn your newsletter into a podcast.
                </p>
                <div className="reveal-scale-down" data-reveal-delay="500">
                  <audio
                    ref={this.audioPlayer}
                    src="https://letterparrot.s3.amazonaws.com/audio/demo.a9ae75fe-dd4b-4a0f-99f6-32335ae39b82.mp3">
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                  <Button color="primary" wideMobile style={{ borderRadius: '100px', outline: '0' }} onClick={this.playAudio}>
                    Listen to sample audio <i style={{ lineHeight: '1.6', marginLeft: '5px' }} class="fas fa-volume-up"></i>
                  </Button>
                </div>
              </div>
            </div>
            <div className="hero-figure illustration-element-01 reveal-scale-down" data-reveal-delay="600">
              <Image
                className="has-shadow"
                src={require('./../../assets/images/parrotnews.png')}
                alt="Hero"
                width={896}
                height={502} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;