import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  function openEmailClient(evt) {
    window.open('mailto:hello@letterparrot.com', 'mail');
    evt.preventDefault()
  }

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
        <a onClick={openEmailClient} href="mailto:hello@letterparrot.com">Contact us</a>
        </li>
        {/* <li>
          <Link to="/about-us/">About us</Link>
        </li>
        <li>
          <Link to="/faqs/">FAQ's</Link>
        </li> */}
        {/* <li>
          <Link to="/support/"><a href="mailto:help@letterparrot.com">Support</a></Link>
        </li> */}
      </ul>
    </nav>
  );
}

export default FooterNav;