import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
      style={{ width: '30%'}}
    >
      <h5 className="m-0">
        <Link to="/" style={{  textDecoration: 'none', display: 'flex', alignItems: 'center'}}>
          <Image
            style={{ float: 'left', }}
            src={require('./../../../assets/images/parrot2.svg')}
            alt="letterparrot logo"
            width={50}
            height={50} />
          <div style={{ marginLeft: '10px', fontSize: '24px '}}>letterparrot</div>
        </Link>
      </h5>
    </div>
  );
}

export default Logo;